<template>
  <el-card shadow="never">
    <div class="card-header">
      <el-page-header @back="goBack">
        <template #content>
          <span class="text-large font-600 mr-3"> {{title}} </span>
        </template>
      </el-page-header>
    </div>
  </el-card>
  <el-card shadow="never" style="margin-top:20px">
    <el-form :model="formData" label-width="120px">
      <el-form-item label="标题">
        <el-input v-model="formData.title" />
      </el-form-item>
      <el-form-item label="类型">
        <el-radio-group v-model="formData.type">
          <el-radio label="1" >自由行</el-radio>
          <el-radio label="2" >组织安排</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="发布时间">
        <el-date-picker
          v-model="formData.releaseTime"
          type="datetime"
          value-format="YYYY-MM-DD HH:mm:ss"
          placeholder="请选择时间"
        />
      </el-form-item>
      <el-form-item label="发布时间段">
        <el-radio-group v-model="formData.timeType">
          <el-radio label="1" >上午</el-radio>
          <el-radio label="2" >下午</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form inline v-show="false" label-width="120px">
        <el-form-item label="开始时间">
          <el-date-picker
            v-model="formData.startTime"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="请选择时间"
          />
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker
            v-model="formData.endTime"
            type="date"
            value-format="YYYY-MM-DD"
            placeholder="请选择时间"
          />
        </el-form-item>
      </el-form>
      <el-form-item label="详情">
        <el-input
          v-model="formData.content"
          type="textarea"
          autosize
          placeholder="请输入详情"
          maxlength="250"
          show-word-limit
        />
        <!-- <vue-ueditor-wrap v-model="formData.content" :config="ueConfig"></vue-ueditor-wrap> -->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="saveCulture">保存</el-button>
        <el-button @click="resCulture">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
  import { ElMessage } from 'element-plus'
  import api from '@/api/index.js'
  import { defineComponent, ref } from 'vue'
  export default defineComponent({
    setup() {
      const formData = ref({
        id:'',
        title:'',
        type:'1',
        timeType:'1',
        releaseTime:'',
        startTime:'',
        endTime:'',
        content:'',
      })
      const title = ref('添加行程')
      const ueConfig = ref({// 初始容器高度
        initialFrameHeight: 300,
        // 初始容器宽度
        initialFrameWidth: '99%',
        // 上传文件接口
        enableAutoSave: false,
        elementPathEnable: false,
        UEDITOR_HOME_URL: "/static/",
        wordCount: false,})
      return {
        title,
        formData,
        ueConfig,
      }
    },
    methods: {
      goBack(){
        this.$router.go(-1)
      },
      get(){
        let that = this
        api.cultureApi.get({id:that.formData.id}).then(res => {
            if (res.code === 0) {
              that.formData.title=res.data.title
              that.formData.type=res.data.type.toString()
              that.formData.timeType=res.data.timeType.toString()
              that.formData.releaseTime=res.data.releaseTime
              that.formData.startTime=res.data.startTime
              that.formData.endTime=res.data.endTime
              that.formData.content=res.data.content
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      saveCulture(){
        let that = this
        api.cultureApi.set(that.formData).then(res => {
            if (res.code === 0) {
              ElMessage({
                message: res.message,
                type: 'success',
              })
              that.goBack()
            }else{
              ElMessage.error(res.message)
            }
        }).catch((res) => {
            ElMessage.error(res.message);
        })
      },
      resCulture(){
        this.formData.title=''
        this.formData.type='1'
        this.formData.timeType='1'
        this.formData.releaseTime=''
        this.formData.startTime=''
        this.formData.endTime=''
        this.formData.content=''
      }
    },
    mounted () {
      this.formData.id = this.$route.query.id
      if(this.$route.query.id){
        this.get()
      }
    }
  })
</script>